function Footer({ route }: any) {
  // const isOrderStatusPage = route?.includes("order-status");
  return (
    <div
      class={`absolute right-0 left-0 position-mob position-class ${
        route === "/checkout" ? "non-position" : ""
      } `}
    >
      <footer className="bg-[#222] text-white text-center py-2">
        <div className="bottom-bar">
          All Rights Reserved
        </div>
      </footer>
    </div>
  );
}

export default Footer;
